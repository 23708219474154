import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  @Input() message: string = '';
  @Input() isError: boolean = false;
  @Input() isSuccess: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.closeMessage();
    }, env.waitTimes.alerts);
  }

  closeMessage() {
    this.close.emit();
  }

}
