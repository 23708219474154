import { Component, Inject, Renderer2, SecurityContext } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { gtpRutValidator } from '../../validators/rut-validator';
import { UtilsService } from 'src/app/services/utils.service';
import { rutValidate } from 'rut-helpers';
import { CredencialesService } from 'src/app/services/credenciales.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { environment as env } from '../../../environments/environment';

declare var AwsWafCaptcha: any;
declare var AwsWafIntegration: any;

@Component({
  selector: 'app-ingreso-credenciales',
  templateUrl: './ingreso-credenciales.component.html',
  styleUrls: ['./ingreso-credenciales.component.scss']
})
export class IngresoCredencialesComponent {
  public credencialesForm!: FormGroup;
  public activateRutHelper = false;
  public token: string|undefined;
  public messages: { text: string, isError: boolean, isSuccess: boolean }[] = [];
  public seeFormulario = false;
  public seeMsg = false;
  public seeLoading = false;
  public formCredenciales: any = {};
  public msg: string = '';
  public id: string | null = '';
  public linkTerminos: string = '';
  public serviceDisplayName: string = '';
  public validacionLinkResponse: any = {};
  public idOrganizacionEncrypted: string = '';
  public idTransaccionEncrypted: string = '';
  public credVars: any[] = [];
  public validarForm: boolean = true;
  public logoUrl: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private readonly route: ActivatedRoute,
    private credencialesService: CredencialesService,
    private readonly titleService: Title,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.token = undefined;
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.cambiosEnUrl();
  }

  cambiosEnUrl(): void {
    this.route.queryParamMap.subscribe((val: any) => {
      const organizacion = val?.params?.['idOrganizacion'];
      const id = val?.params?.['id'];
      this.datosPorUrl(organizacion, id)
    });
  }

  datosPorUrl(organizacion: string | null, id: string | null): void {
    const stringSeguro = this.sanitizer.sanitize(SecurityContext.HTML, organizacion);
    const stringSeguro2 = this.sanitizer.sanitize(SecurityContext.HTML, id);

    if(!stringSeguro && !stringSeguro2){
      this.msg = 'URL invalida';
      this.seeFormulario = false;
      this.seeMsg = true;
      this.titleService.setTitle("error-url");
      return
    }

    const org = stringSeguro ? stringSeguro : '';
    const idTX = stringSeguro2 ? stringSeguro2 : '';

    this.seeLoading = true;
    this.credencialesService.validacionLink(org, idTX).subscribe(
      (data: any) => {

        if(Object.keys(data).length > 0 && data?.DatosBasicosSolicitud?.EstadoTransaccion == 'IN-PROGRESS'){
          if(data?.DatosBasicosSolicitud?.SubEstadoTransaccion == 'FORM-LOADED'){  // OK
            this.loadScript();
            this.validacionLinkResponse = data?.DatosInit;
            this.seeFormulario = true;
            this.titleService.setTitle("widget-form");
            this.seeMsg = false;
            this.linkTerminos = data?.DatosInit?.informVars?.linkTerminosCondiciones || '';
            this.serviceDisplayName = data?.DatosInit?.informVars?.serviceDisplayName || '';
            this.idOrganizacionEncrypted = org;
            this.idTransaccionEncrypted = idTX;
            this.credVars = data?.DatosInit?.credVars || [];
            this.initFormCredenciales(data?.DatosInit?.credVars, data?.DatosInit?.aceptaTerminos);
            this.seeLoading = false;
            this.logoUrl = data?.DatosInit?.informVars?.logoUrl || '';
          } else {
            this.msg = 'Sincronizador Credenciales - Ha ocurrido un error al cargar formulario';
            this.seeFormulario = false;
            this.seeMsg = true;
            this.titleService.setTitle("error-form");
            this.seeLoading = false;
          }
        } else if (Object.keys(data).length > 0 && data?.DatosBasicosSolicitud?.EstadoTransaccion == 'ERROR') {
          if(data?.DatosBasicosSolicitud?.SubEstadoTransaccion == 'SEC-ERROR'){
            this.msg = 'Sincronizador Credenciales - Se genera error por incumplimiento de reglas de seguridad';
            this.seeFormulario = false;
            this.seeMsg = true;
            this.titleService.setTitle("error-security");
            this.seeLoading = false;
          } else {
            this.msg = 'Sincronizador Credenciales - Ha ocurrido un error al cargar formulario';
            this.seeFormulario = false;
            this.seeMsg = true;
            this.titleService.setTitle("error-form");
            this.seeLoading = false;
          }
        } else {
          this.msg = 'Sincronizador Credenciales - Ha ocurrido un error al cargar formulario';
          this.seeFormulario = false;
          this.seeMsg = true;
          this.titleService.setTitle("error-form");
          this.seeLoading = false;
        }
      },
      ({ error }) => {
        this.msg = error?.error?.message || error?.message || 'Sincronizador Credenciales - Ha ocurrido un error al cargar formulario';
        this.seeFormulario = false;
        this.seeMsg = true;
        this.titleService.setTitle("error-form");
        this.seeLoading = false;
    }); 
  }

  initFormCredenciales(credVars: any[], aceptaTerminos: any): void {
    if(credVars && credVars.length > 0) {
      this.credencialesForm = this.formBuilder.group({});

      for(const elemento of credVars) {
        const validadores: any[] = elemento.type === 'RUT_TYPE' ? [Validators.required, gtpRutValidator()] : [Validators.required];
        const valor: any = elemento.type === 'RUT_TYPE' ? this.formatearRut(elemento?.value) : elemento?.value;
        const disabled: boolean = elemento.blocked === true ? true : false;

        this.credencialesForm.addControl(elemento.name, new FormControl({value: valor || '', disabled: disabled}, validadores));
      }
    } else {
      this.credencialesForm = this.formBuilder.group({});
    }

    if(aceptaTerminos === true || aceptaTerminos === false)
      this.credencialesForm.addControl("aceptaTerminos", new FormControl(aceptaTerminos, Validators.required));

    this.credencialesForm.addControl("emailDestino", new FormControl('', Validators.required));
    this.seeFormulario = true;
    this.titleService.setTitle("widget-form");
    this.eventForm();    
  }

  eventForm() {
    this.credencialesForm.valueChanges.subscribe();
  }

  onSubmitForm(showCaptcha: boolean = true): void {
    if (!this.credencialesForm.valid) {
      this.utilsService.validateAllFormFields(this.credencialesForm);
      this.addMessage('Complete el formulario con datos validos', true, false);
      return;
    }

    const formTemp = this.credencialesForm.getRawValue();
        
    let rut = '';
    let hasRut: boolean = false;
    let blockedChanged: boolean = false;

    for (const property in formTemp) {
      const elemento = this.validacionLinkResponse?.credVars.find((e: any) => e.name === property);
      if(elemento) {

        if(this.isRut(elemento?.type)) {
          rut = formTemp[property];
          formTemp[property] = this.formatearRutSimple(rut);
          hasRut = true;
        }

        if(elemento.blocked === true && elemento.value != formTemp[property]) {
          blockedChanged = true;
        }
      }
    }

    if(blockedChanged) {
      this.addMessage(`No se deben editar los campos prefijados y deshabilitados`, true, false);
      return;
    }

    if (hasRut && !rutValidate(rut)) {
      this.addMessage('Ingrese un rut valido', true, false);
      return;
    }
    
    if(!formTemp.emailDestino || (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formTemp.emailDestino)) === false) {
      this.addMessage('Debe ingresar un correo valido', true, false);
      return;
    }

    if(formTemp?.aceptaTerminos === false) {
      this.addMessage('Debe aceptar los terminos del contrato', true, false);
      return;
    }

    this.formCredenciales = formTemp;

    if(showCaptcha === true){
      this.showMyCaptcha();
    } else {
      this.createSendForm();
    }
    
  }

  createSendForm(): void {
    const credsObject: any = {
      attributes: [{
        "name": "orgAccess",
        "value": true
      },
      {
          "name": "otherOrgAccess",
          "value": ""
      }]
    };
    for(const element of Object.keys(this.formCredenciales)) {
      const elementOnCredentials = this.credVars.find((e: any) => e.name == element);
      if(elementOnCredentials){
        credsObject.attributes.push({
          name: elementOnCredentials.name,
          value: this.formCredenciales[element]
        });
      }
    }

    const rutRequest = `${this.validacionLinkResponse?.informVars?.rut}-${this.validacionLinkResponse?.informVars?.dv}`;
    const serviceName = `${this.validacionLinkResponse?.informVars?.serviceName}`;

    // envio de formulario
    this.seeLoading = true;
    this.credencialesService.iniciarSincronizacion( // cambiar url y comportamiento
      rutRequest,
      this.idOrganizacionEncrypted,
      this.idTransaccionEncrypted,
      this.formCredenciales.emailDestino,
      serviceName,
      this.formCredenciales.aceptaTerminos,
      credsObject
    ).subscribe(
      (data: any) => {
        if(Object.keys(data).length > 0 && data?.DatosBasicosSolicitud?.EstadoTransaccion == 'IN-PROGRESS'){

          if(data?.DatosBasicosSolicitud?.SubEstadoTransaccion == 'SYNC-1'){  // OK
            this.sincronizacionEtapa2(
              rutRequest,
              this.idOrganizacionEncrypted,
              this.idTransaccionEncrypted,
              this.formCredenciales.emailDestino,
              serviceName,
              this.formCredenciales.aceptaTerminos,
              credsObject
              );
          } else if(data?.DatosBasicosSolicitud?.SubEstadoTransaccion == 'USER-ERROR'){ // error de usuario
            this.addMessage('Sincronizador Credenciales - Ha ocurrido un error con los datos ingresados', true, false);
            this.backFromError();
            this.seeLoading = false;
          } else { // error generico
            this.addMessage('Sincronizador Credenciales - Ha ocurrido un error al sincronizar', true, false);
            this.backFromError();
            this.seeLoading = false;
          }
        } else if (Object.keys(data).length > 0 && data?.DatosBasicosSolicitud?.EstadoTransaccion == 'ERROR') { // error bloqueante
          if(data?.DatosBasicosSolicitud?.SubEstadoTransaccion == 'USER-ERROR'){ // error de usuario
            this.mostrarMsgFinal(`Sincronizador Credenciales - Transacción cerrada por un error con los datos ingresados`);
            this.titleService.setTitle("error-user");
            this.seeLoading = false;
          } else if(data?.DatosBasicosSolicitud?.SubEstadoTransaccion == 'SEC-ERROR'){ // error de usuario
            this.mostrarMsgFinal(`Sincronizador Credenciales - Se genera error por incumplimiento de reglas de seguridad`);
            this.titleService.setTitle("error-security");
            this.seeLoading = false;
          } else { // error generico
            this.mostrarMsgFinal(`Sincronizador Credenciales - Transacción cerrada por error inesperado`);
            this.titleService.setTitle("error-default");
            this.seeLoading = false;
          }
        } else {
          this.addMessage('Sincronizador Credenciales - Ha ocurrido un error al sincronizar', true, false);
          this.backFromError();
          this.seeLoading = false;
        }
      },
      ({ error }) => {
        this.addMessage(error?.error?.message || error?.message || 'Sincronizador Credenciales - Ha ocurrido un error al sincronizar', true, false);
        this.backFromError();
        this.seeLoading = false;
    }); 
    // envio de formulario
  }

  sincronizacionEtapa2(
    rut: string, 
    idOrganizacionEncrypted: string, 
    idTransaccionEncrypted: string, 
    emailDestino: string, 
    servicioSolicitado: string,
    aceptaTerminos: boolean,
    credsObject: any
  ): void {
    this.credencialesService.iniciarSincronizacionEtapa2( // cambiar url y comportamiento
      rut,
      idOrganizacionEncrypted,
      idTransaccionEncrypted,
      emailDestino,
      servicioSolicitado,
      aceptaTerminos,
      credsObject
    ).subscribe(
      (data: any) => {
        if(Object.keys(data).length > 0 && data?.DatosBasicosSolicitud?.EstadoTransaccion == 'COMPLETED'){

          if(data?.DatosBasicosSolicitud?.SubEstadoTransaccion == 'SYNC-2') {
            this.mostrarMsgFinal(`Sincronizador Credenciales - Las credenciales se han guardado exitosamente.`); //\nHemos informado a ${this.organizacion}.
            this.titleService.setTitle("gtp-success");
            this.seeLoading = false;
          } else {
            this.addMessage('Sincronizador Credenciales - Ha ocurrido un error al sincronizar', true, false);
            this.backFromError();
            this.seeLoading = false;
          }
        } else if(Object.keys(data).length > 0 && data?.DatosBasicosSolicitud?.EstadoTransaccion == 'ERROR') { // error bloqueante
          this.mostrarMsgFinal(`Sincronizador Credenciales - Transacción cerrada por un error al sincronizar`);
          this.titleService.setTitle("error-sync");
          this.seeLoading = false;
        } else {
          this.addMessage('Sincronizador Credenciales - Ha ocurrido un error al sincronizar', true, false);
          this.backFromError();
          this.seeLoading = false;
        }

      },
      ({ error }) => {
        this.addMessage(error?.error?.message || error?.message || 'Sincronizador Credenciales - Ha ocurrido un error al sincronizar', true, false);
        this.backFromError();
        this.seeLoading = false;
    }); 
  }

  backFromError(): void {
    this.validarForm = true;
    this.hiddenButtons('enviarBtn', false);
    const container = document.querySelector("#my-captcha-container");
    if (container) {
      container.innerHTML = '';
    }
  }

  mostrarMsgFinal(msg: string): void {
    this.seeFormulario = false;
    this.seeMsg = true;
    this.msg = msg;
  }

  cerrarPestana(): void {
    window.close()
  }

  addMessage(mensaje: string, isError: boolean, isSuccess: boolean) : void {
    this.messages.push({ text: mensaje, isError: isError, isSuccess: isSuccess });
  }

  removeMessage(index: number) {
    this.messages.splice(index, 1);
  }

  formatearRut(rut: string): string {
    const actual = rut.replace(/^0+/, "");
    if (actual != '' && actual.length > 1) {
      const sinPuntos = actual.replace(/\./g, "");
      const actualLimpio = sinPuntos.replace(/-/g, "");
      const inicio = actualLimpio.substring(0, actualLimpio.length - 1);
      let rutPuntos = "";
      const i = 0;
      let j = 1;
      for (let i = inicio.length - 1; i >= 0; i--) {
        const letra = inicio.charAt(i);
        rutPuntos = letra + rutPuntos;
        if (j % 3 == 0 && j <= inicio.length - 1) {
          rutPuntos = "." + rutPuntos;
        }
        j++;
      }
      const dv = actualLimpio.substring(actualLimpio.length - 1);
      rutPuntos = rutPuntos + "-" + dv;
      return rutPuntos;
    }
    return rut;
  }

  formatearRutSimple(rut: string) {
    const actual = rut.replace(/^0+/, "");
    if (actual != '' && actual.length > 1) {
      const sinPuntos = actual.replace(/\./g, "");
      const actualLimpio = sinPuntos.replace(/-/g, "");
      const inicio = actualLimpio.substring(0, actualLimpio.length - 1);
      const dv = actualLimpio.substring(actualLimpio.length - 1);
      const rutSimple = inicio + "-" + dv;
      return rutSimple;
    }
    return rut;
  }
  
  validadorType(type: string): string {
    // type = ['RUT_TYPE', 'PASSWORD-TEXT'] --> validar nomenclatura
    if(!type)
      return 'text';
    if(type.toUpperCase().includes('PASSWORD'))
      return 'password';
    if(type.toUpperCase().includes('NUMBER'))
      return 'number';
    if(type.toUpperCase().includes('DATE'))
      return 'date';
    if(type.toUpperCase().includes('URL'))
      return 'url';

    return 'text';
  }

  isRut(type: string): boolean {
    if(type)
      return type.toUpperCase().includes("RUT");
    return false;
  }


  // AWS CAPTCHA


  loadScript() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = `${env.awsCaptcha.integrationUrl}jsapi.js`; // Reemplaza <Integration URL> con la URL real
    script.defer = true;
    this.renderer.appendChild(this.document.body, script);
  }

  showMyCaptcha(reintentos: number = 5, intervalo: number = 1000) {
    if (typeof (window as any)['AwsWafCaptcha'] !== 'undefined' && typeof (window as any)['AwsWafIntegration'] !== 'undefined') {
      var container = document.querySelector("#my-captcha-container");
      this.validarForm = false;
      this.hiddenButtons('enviarBtn', false);
      (window as any)['AwsWafCaptcha'].renderCaptcha(container, {
          apiKey: `${env.awsCaptcha.apiKey}`,
          onSuccess: () => {
            console.log('Captcha completed successfully.');
            this.hiddenButtons('enviarBtn', true);
            this.clickEnviar();
          },
          onError: () => {
            console.error('Captcha with errors.');
            this.showErrorCaptcha("Ha ocurrido un error al intentar validar el captcha")
          }
          //...other configuration parameters as needed...
      });
    } else {
      console.error('AwsWafCaptcha or AwsWafIntegration is not loaded');
      if (reintentos > 0) {
        setTimeout(() => {
          this.showMyCaptcha(reintentos - 1, intervalo);
        }, intervalo);
      }
    }
  }

  hiddenButtons(id: string, show: boolean) {
    const element = document.getElementById(id);

    if(element){
      if ( show && element.classList.contains('display-none') ){
        element.classList.remove('display-none');
      } else if ( !show && !element.classList.contains('display-none')) {
        element.classList.add('display-none');
      }
    }

  }

  clickEnviar(): void {
    const element = document.getElementById('enviarBtn');
    if(element){
      element.click();
    }
  }

  showErrorCaptcha(text: string): void {
    const element = document.getElementById('error-captcha');
    if(element){
      if (element.classList.contains('display-none') ){ // no se esta mostrando
        element.classList.remove('display-none');
        element.innerHTML = text;
        this.timerOcultarErrorCaptcha();
      } else { // se esta mostrando
        element.classList.add('display-none');
        setTimeout(() => { //delay
          element.classList.remove('display-none');
          element.innerHTML = text;
          this.timerOcultarErrorCaptcha();
        }, 500);
      }
    }
  }

  timerOcultarErrorCaptcha(): void {
    setTimeout(() => {
      const element = document.getElementById('error-captcha');
      if(element){
        element.classList.add('display-none');
      }
    }, 8000);
  }

  abrirPdf(): void {
    window.open(this.linkTerminos, '_blank');
  }

  toggleTypePassword(id: string): void {
    const element = document.getElementById(id);
    if(element){
      if (element.getAttribute('type') === 'password') {
        element.setAttribute('type', 'text');
      } else {
        element.setAttribute('type', 'password');
      }
    }
  }

}
