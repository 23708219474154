export const environment = {
    production: false,
    backend: {
      service: {
        credenciales: {
          url: 'https://api.dev.gestionatupyme.cl/SincronizadorCredencialesExterno/'
        }
      }
    },
    awsCaptcha: {
      integrationUrl: 'https://4f69b1187f6d.us-east-1.captcha-sdk.awswaf.com/4f69b1187f6d/',
      apiKey: 'jx5bK2NnLwRU1mOnJUCNKAlcVc1h3sEEO3TNLsRlb9IIJIdVSO7JbZ767nC6B8Q2cMXqvFlAx+Z/zl3yXWTPdrX7F8e3zCiGobHK+ZUzGjSo1gq43fAzBvXwoT9+wNjYy6+pj0WCwsWCtycCgIYNE0Kk8/ycNfCAw8FFeDE1jmgOgJXmqTInhstJkhvi9sEMABGNKA4a+Ptf+VHoDlOkZqwn6W4/efCNTaSEZ7cJUmIpotLMkROOp3VwwZ1XdvEfMsJL39ZxjZlxIcyIMMLB9enubAFK2xBU5BeNwOJ/L+V7UxVwSskJqQx9Y6LyTrdyOU9I3sfJT2TcwoHGf5Q2WBJDBfDFO9tTfJ58jHBuF1Si7VvfitKX7X2DBMuETGc9cEDsINon2Q4BfrYrIx4N5oqilYrg7SpnEK9Dfu2r5UJGtrRG6tJ00EzUHs7ai4nI6a74vGqJQYWoe1nbXlfxJzrtA8NR8fd786Y2mxELpeUnLnZjqLbcWqqzviuZLBux+lop2dyTDDeGoHbqkShWtCb3uYxXl5aYoHqpRtNjw3urHZIsZNtLN5N787xfksvja6INiPE3Uvh9t0fDzGMso74yoHNAu/H8W2LxBLQaVwk+LbbDOg1DZ5nzwwoGB0bxspCtRcK6k0XzFlkATY1l4gXbMANTJhJnNPz+7bsELqs=_0_1'
    },
    waitTimes: {
      alerts: 10000
    }
  };