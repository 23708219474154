import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CredencialesService {
  private endpoint: string = environment.backend.service.credenciales.url;

  constructor(
    private http: HttpClient,
  ) { }

  validacionLink(idOrganizacion: string = '', idTransaccion: string = ''): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idTransaccionEncrypted", idTransaccion);
    params = params.append("idOrganizacionEncrypted", idOrganizacion);
    return this.http.get<any>(`${this.endpoint}iniciarSincronizacion`, { params })
  }

  iniciarSincronizacion(
    rut: string, 
    idOrganizacionEncrypted: string, 
    idTransaccionEncrypted: string, 
    emailDestino: string, 
    servicioSolicitado: string,
    aceptaTerminos: boolean,
    credsObject: any
  ): Observable<any> {

    const payload: any = {
      rut: rut,
      idOrganizacionEncrypted: idOrganizacionEncrypted,
      idTransaccionEncrypted: idTransaccionEncrypted,
      requestOrigin: "WEB",
      emailDestino: emailDestino,
      servicioSolicitado: servicioSolicitado,
      aceptaTerminos: aceptaTerminos,
      credsObject: credsObject
    }
    return this.http.post<any>(`${this.endpoint}sincronizarCredencialesEtapa1`, payload);
  }

  iniciarSincronizacionEtapa2(
    rut: string, 
    idOrganizacionEncrypted: string, 
    idTransaccionEncrypted: string, 
    emailDestino: string, 
    servicioSolicitado: string,
    aceptaTerminos: boolean,
    credsObject: any
  ): Observable<any> {

    const payload: any = {
      rut: rut,
      idOrganizacionEncrypted: idOrganizacionEncrypted,
      idTransaccionEncrypted: idTransaccionEncrypted,
      requestOrigin: "WEB",
      emailDestino: emailDestino,
      servicioSolicitado: servicioSolicitado,
      aceptaTerminos: aceptaTerminos,
      credsObject: credsObject
    }
    return this.http.post<any>(`${this.endpoint}sincronizarCredencialesEtapa2`, payload);
  }
}
