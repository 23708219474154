<div class="row">
    <div class="col-sm-12 col-md-10 mx-auto message" [ngClass]="{'error': isError, 'success': isSuccess}">
        
        <ng-container *ngIf="isError && !isSuccess">
            <div class="alert alert-danger text-center" role="alert">
                <span class="close" (click)="closeMessage()">&times;</span>

                {{ message }} 
                <i class="fa fa-exclamation-circle ml-2" aria-hidden="true"></i>
            </div>
        </ng-container>
    
        <ng-container *ngIf="!isError && isSuccess">
            <div class="alert alert-success text-center" role="alert">
                <span class="close" (click)="closeMessage()">&times;</span>

                {{ message }}
                <i class="fa fa-check-circle ml-2" aria-hidden="true"></i>
            </div>
        </ng-container>
        
    </div>  
</div>
