
<div class="col-12 px-0">
    <div class="container container-width bg-formulario">
        <div class="row">
            <div class="col-sm-12 bg-color py-3 text-center px-0 border" *ngIf="!seeLoading && serviceDisplayName">
                <span>Sincronizar credenciales de {{serviceDisplayName || ''}}</span>
            </div>

            <div class="col-sm-12 my-3">
                <app-alert-message *ngFor="let message of messages; let i = index"
                    [message]="message.text"
                    [isError]="message.isError"
                    [isSuccess]="message.isSuccess"
                    (close)="removeMessage(i)"
                ></app-alert-message>
            </div>

            <div class="col-sm-12 my-5" *ngIf="seeLoading">
                <div class="row">
                    <div class="col-sm-12 my-3">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-sm-12 my-3">
                        <span class="d-flex justify-content-center">Cargando...</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-12" [ngClass]="{'d-none': seeLoading}" *ngIf="logoUrl">
                <div class="row">
                    <div class="col-sm-12 mt-1 mb-2">
                        <div class="d-flex justify-content-center">
                            <img alt='Logo empresa' [src]="logoUrl" alt="Logo" class="img-fluid img-size">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 mt-1 mb-4" *ngIf="seeFormulario" [ngClass]="{'d-none': seeLoading}">
                <form [formGroup]="credencialesForm">

                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-prepend mr-3">
                                <span class="mb-2">
                                    Se le ha solicitado que actualice el formulario con las credenciales de {{serviceDisplayName || ''}}.
                                </span>
                            </div>
                        </div>
                    </div>
        
                    <ng-container *ngIf="validacionLinkResponse?.credVars && validacionLinkResponse?.credVars.length > 0">

                        <ng-container *ngFor="let variable of validacionLinkResponse?.credVars">

                            <div class="form-group my-3"  [ngClass]="{'has-error': activateRutHelper && isRut(variable.type)}">
                                <label [attr.for]="variable.name">{{ variable?.displayName || variable?.name || '' }}:</label>

                                <ng-container *ngIf="validadorType(variable.type) === 'password'; else notPassTemplate">
                                    <div class="input-group">
                                        <input 
                                            [attr.type]="'password'"
                                            class="form-control border-pass"
                                            [attr.id]="variable.name"
                                            [attr.placeholder]="variable?.displayName || variable?.name || ''"
                                            [formControlName]="variable.name"
                                            [attr.id]="variable.name"
                                            [attr.aria-describedby]="'button-' + variable.name"
                                        >
                                        <button 
                                            class="btn btn-outline-primary btn-pass" 
                                            type="button" 
                                            [attr.title]="'Mostrar/Ocultar ' + (variable?.displayName || variable?.name || '')" 
                                            [attr.id]="'button-' + variable.name"
                                            (click)="toggleTypePassword(variable.name)"
                                        >
                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                        </button>                                  
                                    </div>
                                </ng-container>
                                <ng-template #notPassTemplate>
                                    <input 
                                        [attr.type]="validadorType(variable.type)"
                                        class="form-control"
                                        [attr.id]="variable.name"
                                        [attr.placeholder]="variable?.displayName || variable?.name || ''"
                                        [formControlName]="variable.name"
                                        [attr.id]="variable.name"
                                    >
                                </ng-template>
                                <div class="w-100">
                                    <app-error [control]="credencialesForm.get(variable.type)"></app-error>
                                </div>
                            </div>

                        </ng-container>
                        

                    </ng-container>

                    <div class="form-group my-3">
                        <label>Email Asociado: <i class="fa fa-exclamation-circle text-warning pointer-cursor" aria-hidden="true" ngbTooltip="Ingrese la dirección email al que le llegó la solicitud" triggers="click:blur"></i></label>
                        <div class="input-group">
                            <input type="email" class="form-control col-md-12" placeholder="Email Asociado" id="emailDestino" formControlName="emailDestino" name="emailDestino">
                        </div>
                        <div class="w-100">
                            <app-error [control]="credencialesForm.get('emailDestino')"></app-error>
                        </div>
                    </div>

                    <div class="form-group mt-3" *ngIf="validacionLinkResponse?.aceptaTerminos == true || validacionLinkResponse?.aceptaTerminos == false">
                        <div class="input-group">
                            <div class="col-sm-12 px-0" *ngIf="linkTerminos">
                                <div class="input-group-prepend">
                                    <span><strong class="font-size-label"> <a (click)="abrirPdf()">Revisar Terminos y Condiciones</a></strong></span>
                                </div>
                            </div>
                            <div class="input-group-prepend">
                                <span><strong class="font-size-label">He leído y acepto los términos y condiciones de uso:</strong></span>
                            </div>
                            <label class="switch switch-primary">
                                <input type="checkbox" id="aceptaTerminos" formControlName="aceptaTerminos">
                                <span></span>
                            </label>
                        </div>
                    </div>

                    <div class="form-group mt-5">
                        <div class="container mx-auto">
                            <div id="my-captcha-container">
                                <!-- The contents of this container will be replaced by the captcha widget -->
                            </div>
                        </div>
                        <div class="w-100">
                            <span class="text-danger font-weight-bolder mt-2 error-custom display-none" id="error-captcha"></span>
                        </div>
                    </div>
        
                    <div class="row mt-3">
                        <div class="col text-center">
                            <button class="btn btn-envio btn-primary" (click)="onSubmitForm()" *ngIf="validarForm">Validar Formulario</button>
                            <button class="btn btn-envio btn-primary display-none" (click)="onSubmitForm(false)" id="enviarBtn">Enviar</button>
                        </div>
                    </div>
        
                </form>
            </div>

            <div class="col-sm-12 mt-4 mb-5" *ngIf="seeMsg" [ngClass]="{'d-none': seeLoading}">
                <span>{{msg}}</span>
            </div>
        </div>
    </div>
</div>